#wrapper {
	overflow-x: hidden;
}

#sidebar-wrapper {
	min-height: 100vh;
	margin-left: -16rem;
	-webkit-transition: margin 0.25s ease-out;
	-moz-transition: margin 0.25s ease-out;
	-o-transition: margin 0.25s ease-out;
	transition: margin 0.25s ease-out;
	background: #0e5371!important;
}

#sidebar-wrapper .sidebar-heading {
	padding: 0.875rem 1.25rem;
	font-size: 1.2rem;
	background: #0e5371!important;
	border-bottom: white solid 2px;
}

#sidebar-wrapper .list-group {
	width: 16rem;
	background: #0e5371!important;
}

#sidebar-wrapper .list-group-item {
	width: 16rem;
	background: #0e5371!important;
	color: white;
}

#sidebar-wrapper .list-group-item.active {
	width: 16rem;
	/* background:black !important; */
	background: #23333b !important;
	color: white;
}

#sidebar-wrapper .list-group-item:hover {
	background: #23333b !important;
}

.inactive {
	color: white !important;
	text-decoration: none !important;
}

.active {
	color: white !important;
	background: white !important;
	/* font-weight: bolder !important; */
}

.logoutButton {
	
	bottom: 0px;
	cursor: pointer;
	width: 16rem;
	background: #0e5371!important;
	color: white;
    min-height: fit-content;
	justify-content: center;
	align-content: center;
	padding-left: 1.8%;
	padding-top: 20px;
	padding-bottom: 20px;
	border-top: white solid 2px;
}

.submenuText {
	margin-left: 50px !important;
}

.iconClass {
	margin-left: 0.7rem;
}

@media (min-width: 450px) {
	#sidebar-wrapper {
		margin-left: 0;
	}

	#page-content-wrapper {
		min-width: 0;
		width: 100%;
	}

	#wrapper.toggled #sidebar-wrapper {
		margin-left: -16rem;
	}
}
