.loginContainer {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	text-align: center;
}

.cardLogin {
	width: 30vw;
	margin-bottom: auto;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10%;
	/* PROBAR CON LOGO BLANCO O CAMBIAR COLOR!! */
	background: #0e5371!important;
	color: white;
	box-shadow: 0px 1px 4px 0px #000000;
	padding: 4%;
}

.loginButton {
	background-color: #ffffff;
	color: black;
	width: 100%;
	border: none;
	box-shadow: 2px 2px 3px 0px #707070;
	height: 50px;
}

.login-backBtn {
	color: #23333b;
}

@media screen and (max-width: 992px) {
	.cardLogin {
		width: 80vw;
		margin-left: auto;
		margin-right: auto;
		/* PROBAR CON LOGO BLANCO O CAMBIAR COLOR!! */
		background: #23333b;
		color: white;
		text-align: center;
		box-shadow: 0px 1px 4px 0px #000000;
		padding: 4%;
	}
}
